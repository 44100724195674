import React, {createContext, ReactNode, useEffect, useState} from "react";
import {onAuthChange, signOutUser as firebaseSignOut } from "../../authService";
import {User} from "firebase/auth";

type AuthContextType = {
  user: User | null
  isAuthLoading: boolean
  isDemoMode: boolean
  signOutUser: () => void
  activateDemoMode: () => void
}

type AuthProviderProps = {
  children: ReactNode
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthLoading: true,
  isDemoMode: false,
  signOutUser: () => {},
  activateDemoMode: () => {}
})

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null)
  const [isAuthLoading, setIsAuthLoading] = useState(true)
  const [isDemoMode, setIsDemoMode] = useState(false)

  useEffect(() => {
    const unsubscribe = onAuthChange((user: User | null) => {
      setUser(user)
      setIsAuthLoading(false)
      if (user) {
        setIsDemoMode(false)
      }
    })
    return () => unsubscribe()
  }, []);

  const signOutUser = () => {
    setUser(null)
    setIsDemoMode(false)
    firebaseSignOut().catch((error) => console.error('Sign out failed', error))
  }

  const activateDemoMode = () => {
    setUser(null)
    setIsDemoMode(true)
  }

  return (
    <AuthContext.Provider value={{ user, isAuthLoading, isDemoMode, signOutUser, activateDemoMode }}>
      {children}
    </AuthContext.Provider>
  )
}
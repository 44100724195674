export const sanitizeDataForFirestore = (data: Array<any>) => {
  return data.map(item => removeUndefinedPropertiesForFirestore(item))
}

const removeUndefinedPropertiesForFirestore = (data: any) => {
  const sanitizedData: { [key: string]: any} = {}
  Object.keys(data).forEach(key => {
    const value = data[key]
    if (value !== undefined) {
      sanitizedData[key] = value
    } else {
      console.error(`undefined field ${key} had to be removed before saving because it was undefined`)
    }
  })
  return sanitizedData
}
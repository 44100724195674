import {Transaction} from "../domain/Transaction";
import {generateEmptyMonths} from "./generateEmptyMonths";
import config from "../config";
import {BaseMonth} from "../domain/Month";

export const organizeTransactionsByMonth = (transactions: Transaction[]): BaseMonth[] => {
  const months = generateEmptyMonths(
    config.appStartYear,
    config.appStartMonth,
    config.appEndYear,
    config.appEndMonth
  )

  const monthsMap: { [key: string]: BaseMonth} = {}

  months.forEach(month => {
    const monthKey = `${month.year}-${month.month}`
    monthsMap[monthKey] = {...month}
  })

  transactions.forEach(transaction => {
    const monthKey = `${transaction.year}-${transaction.month}`
    if (monthsMap[monthKey]) {
      monthsMap[monthKey].transactions.push(transaction)
    }
  })
  return Object.values(monthsMap)
}
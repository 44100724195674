import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import CollapsableMonth from "./CollapsableMonth";
import {getCurrentMonth} from "../../utils/getCurrentMonth";
import {Transaction} from "../../domain/Transaction";
import {Month} from "../../domain/Month";

type MonthTableProps = {
  months: Month[],
  handleTransactionCreate: (month: number, year: number) => void,
  handleTransactionUpdate: (updatedTransaction: Transaction) => void,
  handleTransactionDelete: (transactionId: string) => void
}

const MonthTable: React.FC<MonthTableProps> = ({
                                                 months,
                                                 handleTransactionCreate,
                                                 handleTransactionUpdate,
                                                 handleTransactionDelete
                                               }) => {
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([])
  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    const expandCurrentMonth = () => {
      const [currentMonth, currentYear] = getCurrentMonth()
      const currentMonthIndex = months.findIndex(monthData => monthData.year === currentYear && monthData.month === currentMonth)
      if (currentMonthIndex !== -1) {
        setExpandedIndexes([currentMonthIndex])
      }
    }
    if (isFirstRender) {
      expandCurrentMonth()
      setIsFirstRender(false)
    }
  }, [isFirstRender, months]);

  const handleMonthExpand = (index: number) => {
    setExpandedIndexes(expandedIndexes =>
      expandedIndexes.includes(index) ? expandedIndexes.filter(i => i !== index) : [...expandedIndexes, index])
  }

  return (
    <Box>
      {months.map((month, index) => (
        <CollapsableMonth
          key={index}
          index={index}
          monthNumber={month.month}
          yearNumber={month.year}
          balance={month.balance}
          transactions={month.transactions}
          onTransactionCreate={(month: number, year: number) => handleTransactionCreate(month, year)}
          onTransactionUpdate={(updatedTransaction) => handleTransactionUpdate(updatedTransaction)}
          onTransactionDelete={(transactionId: string) => handleTransactionDelete(transactionId)}
          isExpanded={expandedIndexes.includes(index)}
          onExpandClick={handleMonthExpand}
        />
      ))}
    </Box>
  )
}

export default MonthTable
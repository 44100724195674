export const getMonthName = (monthNumber: number): string => {
  if (monthNumber < 1 || monthNumber > 12) {
    throw new Error(`Month number must be between 1 and 12 (received ${monthNumber}`)
  }

  const date = new Date(2000, monthNumber - 1)

  const formatter = new Intl.DateTimeFormat('fr-FR', { month: 'long'})
  return formatter.format(date)
}

export const formatMonthAndYear = (month: number, year: number): string => {
  return `${getMonthName(month)} ${year}`
}

export const formatBalanceWithTwoDecimals = (balance: number) => {
  return (Math.round(balance * 100) / 100).toFixed(2).replace('.', ',')
}
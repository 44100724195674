import {Month} from "../domain/Month";
import {isMonthInThePast} from "../utils/isMonthInThePast";

export const getFirstNegativeBalanceMonthToCome = (months: Month[]): Month | null => {
  for (const month of months) {
    if (isMonthInThePast(month)) {
      continue
    }
    if (month.balance < 0) {
      return month
    }
  }
  return null
}
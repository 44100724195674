import React, {ReactNode} from "react";
import {formatBalanceWithTwoDecimals, getMonthName} from "../../utils/formatValuesUtils";
import {Box, IconButton, PaletteColor, Typography} from "@mui/material";
import {AddCircleRounded, KeyboardArrowDown, KeyboardArrowRight} from "@mui/icons-material";
import CheckedIcon from '@mui/icons-material/CheckBoxOutlined';

type CollapsableMonthHeaderProps = {
  expanded: boolean,
  onExpandClick: () => void,
  monthNumber: number,
  yearNumber: number,
  balance: number,
  checkedTransactionNumber: number,
  totalTransactionNumber: number,
  monthColor: PaletteColor,
  onTransactionCreate: () => void,
}

export const CollapsableMonthHeader: React.FC<CollapsableMonthHeaderProps> = ({
                                                                                expanded,
                                                                                onExpandClick,
                                                                                monthNumber,
                                                                                yearNumber,
                                                                                balance,
                                                                                checkedTransactionNumber,
                                                                                totalTransactionNumber,
                                                                                monthColor,
                                                                                onTransactionCreate,
                                                                              }) => {
  return <Box
    display='flex'
    alignItems='center'
    onClick={onExpandClick}
    sx={{
      backgroundColor: monthColor.main,
      borderRadius: expanded ? "8px 8px 0px 0px" : 1
    }}>
    <IconButton sx={{color: "white"}}>
      {expanded ? <KeyboardArrowDown/> : <KeyboardArrowRight/>}
    </IconButton>
    <Box>
      <Typography display='inline' variant="h6" color='white' fontWeight='bold'>{getMonthName(monthNumber)}</Typography>
      <Typography display='inline' variant="h6" color='white' ml={1}>{yearNumber}</Typography>
    </Box>
    <Box
      onClick={(e) => expanded ? e.stopPropagation() : null}
      sx={{marginLeft: 'auto'}}
    >
      <IconButton
        sx={{color: "white"}}
        onClick={onTransactionCreate}
        disabled={!expanded}
      >
        <AddCircleRounded/>
      </IconButton>
    </Box>
    <Box display='grid' gridTemplateColumns='1fr 1fr'>
      <HeaderItemContainer>
        <Typography
          variant='h6'
          color='white'
          fontWeight='bold'
        >
          {`${checkedTransactionNumber}/${totalTransactionNumber}`}
        </Typography>
        <CheckedIcon sx={{color: 'white', paddingLeft: 1}}/>
      </HeaderItemContainer>
      <HeaderItemContainer>
        <Typography
          variant="h6"
          textAlign="end"
          color="white"
          fontWeight="bold"
          sx={{
            minWidth: "4em",
            marginRight: 1,
          }}>
          {`${formatBalanceWithTwoDecimals(balance)} €`}
        </Typography>
      </HeaderItemContainer>
    </Box>
  </Box>;
}

const HeaderItemContainer: React.FC<{ children: ReactNode }> = ({children}) => (
  <Box display='flex' flexDirection='row' alignItems='center' justifyContent='flex-end' minWidth={125}>
    {children}
  </Box>
)
import {BaseMonth} from "../domain/Month";
import {createBaseMonth} from "./monthBalanceUtils";

export const generateEmptyMonths = (startYear: number, startMonth: number, endYear: number, endMonth: number): BaseMonth[] => {
  const months: BaseMonth[] = []

  for (let year = startYear; year <= endYear; year++) {
    const yearStartMonth = year === startYear ? startMonth : 1
    const yearEndMonth = year === endYear ? endMonth : 12

    for(let month = yearStartMonth; month <= yearEndMonth; month++) {
      months.push(createBaseMonth(month, year))
    }
  }

  return months
}
import {Button, Typography} from "@mui/material";
import React from "react";
import {User} from "firebase/auth";

type AuthenticatorProps = {
  user: User | null,
  onSignOut: () => void,
  onSignIn: () => void
}

export const Authenticator: React.FC<AuthenticatorProps> = ({ user, onSignOut, onSignIn}) => {
  return <>
    {user ? (
      <>
        <Typography variant="h6" component="div" sx={{marginRight: 6}}>
          Bienvenue, {user.email}
        </Typography>
        <Button color="inherit" variant="outlined" onClick={onSignOut}>Déconnexion</Button>
      </>
    ) : (
      <Button color="inherit" variant="outlined" onClick={onSignIn}>
        Connexion
      </Button>
    )}
  </>;
}
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useTheme} from "@mui/material";
import React, {ReactNode, useEffect, useState} from "react";
import {Recurrence} from "../../domain/Recurrence";
import {DataGrid, GridColDef, GridRowModel} from "@mui/x-data-grid";
import {FrequencyCode, FrequencyNameMapping} from "../../domain/Frequency";
import {TransactionTypeCode} from "../../domain/TransactionType";
import {AddCircleRounded} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {v4 as uuidv4} from 'uuid';
import {grey} from "@mui/material/colors";
import {getMonthName} from "../../utils/formatValuesUtils";
import {AmountWithEuro} from "../AmountWithEuro";

type RecurrenceDialogProps = {
  isOpen: boolean,
  onClose: () => void
  recurrences: Recurrence[],
  handleRecurrencesUpdate: (recurrences: Recurrence[]) => void
}

export const RecurrenceDialog: React.FC<RecurrenceDialogProps> = ({
                                                                    isOpen,
                                                                    onClose,
                                                                    recurrences,
                                                                    handleRecurrencesUpdate
                                                                  }) => {
  const monthOptions = Array.from({length: 12}, (_, i) => ({value: i + 1, label: getMonthName(i + 1)}))
  const theme = useTheme()
  const [recurrenceGridRows, setRecurrenceGridRows] = useState<GridRowModel[]>(mapRecurrencesToGridRows(recurrences))

  useEffect(() => {
    setRecurrenceGridRows(mapRecurrencesToGridRows(recurrences))
  }, [recurrences])

  const onProcessRowUpdate = (updatedRow: GridRowModel) => {
    const newGridRows = recurrenceGridRows.map((row: GridRowModel) => {
      if (row.id === updatedRow.id) {
        return {...row, ...updatedRow}
      }
      return row
    })
    setRecurrenceGridRows(newGridRows)
    return updatedRow
  }

  const onAddRecurrence = () => {
    setRecurrenceGridRows(handleRecurrenceCreate(recurrenceGridRows))
  }

  const onDeleteRecurrence = (deletedRecurrenceRowId: string) => {
    setRecurrenceGridRows(handleRecurrenceDelete(deletedRecurrenceRowId, recurrenceGridRows))
  }

  const onSave = () => {
    const updatedRecurrences = mapGridRowsToRecurrences(recurrenceGridRows)
    handleRecurrencesUpdate(updatedRecurrences)
    onClose()
  }

  const onDialogClose = () => {
    setRecurrenceGridRows(mapRecurrencesToGridRows(recurrences))
    onClose()
  }

  const columns: GridColDef[] = [
    {
      field: 'description',
      headerName: 'Description',
      'width': 200,
      type: 'string',
      editable: true
    },
    {
      field: 'amount',
      headerName: 'Montant',
      'width': 100,
      type: 'number',
      editable: true,
      renderCell: (params) => <AmountWithEuro amount={params.value} />
    },
    {
      field: 'frequency',
      headerName: 'Fréquence',
      'width': 120,
      type: 'singleSelect',
      valueOptions: Object.values(FrequencyCode).map((value) => ({
        value: value,
        label: FrequencyNameMapping[value]
      })),
      editable: true
    },
    {
      field: 'startMonth',
      headerName: 'Premier mois',
      'width': 120,
      type: 'singleSelect',
      valueOptions: monthOptions,
      editable: true
    },
    {
      field: 'startYear',
      headerName: 'Premier année',
      'width': 120,
      type: 'number',
      editable: true
    },
    {
      field: 'endMonth',
      headerName: 'Dernier mois',
      'width': 120,
      type: 'singleSelect',
      valueOptions: monthOptions,
      editable: true
    },
    {
      field: 'endYear',
      headerName: 'Dernière année',
      'width': 120,
      type: 'number',
      editable: true
    },
    {
      field: 'onMonth',
      headerName: 'Mois [annuel]',
      'width': 120,
      type: 'singleSelect',
      valueOptions: monthOptions,
      editable: true
    },
    {
      field: 'delete',
      headerName: 'Supprimer',
      width: 100,
      renderCell: (params) => (
        <GridCellIconButtonContainer>
          <IconButton
            onClick={() => onDeleteRecurrence(params.row.id)}
            color='recurrence'
          >
            <DeleteIcon/>
          </IconButton>
        </GridCellIconButtonContainer>
      )
    }
  ]

  return <Dialog
    open={isOpen}
    onClose={onDialogClose}
    maxWidth="xl"
  >
    <DialogTitle>
      <Box borderRadius={1} sx={{paddingLeft: 1, backgroundColor: theme.palette.recurrence.main, color: "white"}}>
        Récurrences
      </Box>
    </DialogTitle>
    <Button
      startIcon={<AddCircleRounded/>}
      onClick={onAddRecurrence}
      color='recurrence'
      variant='contained'
      sx={{
        marginLeft: 'auto',
        marginRight: 4
      }}
    >
      Ajouter
    </Button>
    <DialogContent>
      <Box style={{height: 400, width: '100%'}}>
        <DataGrid
          rows={recurrenceGridRows}
          columns={columns}
          localeText={{noRowsLabel: 'Pas de récurrence'}}
          hideFooter
          processRowUpdate={onProcessRowUpdate}
          isCellEditable={isCellEditable}
          getCellClassName={getCellBackground}
          sx={{
            '& .MuiDataGrid-withBorderColor': {
              backgroundColor: theme.palette.recurrence.light,
              color: theme.palette.common.black
            },
            '& .disabled-cell': {
              backgroundColor: grey[100]
            }
          }}
        />
      </Box>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onDialogClose}
        color="recurrence"
      >
        Annuler
      </Button>
      <Button
        onClick={onSave}
        variant="contained"
        color="recurrence"
      >
        Enregistrer
      </Button>
    </DialogActions>
  </Dialog>;
}

function handleRecurrenceCreate(previousRecurrenceRows: GridRowModel[]): GridRowModel[] {
  const newRecurrenceDescription = '<Nouvelle récurrence>'

  const newRecurrenceRow = {
    id: uuidv4(),
    type: TransactionTypeCode.ADJUSTMENT,
    description: newRecurrenceDescription,
    amount: 0,
    frequency: FrequencyCode.MONTH
  }
  return [...previousRecurrenceRows, newRecurrenceRow]
}

function handleRecurrenceDelete(deleteRecurrenceRowId: string, previousRecurrenceRows: GridRowModel[]): GridRowModel[] {
  return previousRecurrenceRows.filter(recurrenceRow => recurrenceRow.id !== deleteRecurrenceRowId)
}

function mapRecurrencesToGridRows(recurrences: Recurrence[]): GridRowModel[] {
  return recurrences.map(recurrence => ({
    id: recurrence.id,
    type: recurrence.type,
    description: recurrence.description,
    amount: recurrence.amount,
    frequency: recurrence.frequency,
    startMonth: recurrence.startMonth,
    endMonth: recurrence.endMonth,
    onMonth: recurrence.onMonth,
    endYear: recurrence.endYear,
  }));
}

function mapGridRowsToRecurrences(gridRows: GridRowModel[]): Recurrence[] {
  return gridRows.map(row => ({
    id: row.id,
    type: row.type,
    description: row.description,
    amount: row.amount,
    frequency: row.frequency,
    startMonth: row.startMonth,
    endMonth: row.endMonth,
    endYear: row.endYear,
    onMonth: row.onMonth
  }));
}

const isCellEditable = (params: any) => {
  const {field, row} = params
  if (row.frequency === FrequencyCode.MONTH) {
    if (field === 'onMonth') {
      return false
    }
  }
  if (row.frequency === FrequencyCode.YEAR) {
    if (field === 'startMonth' || field === 'endMonth') {
      return false
    }
  }
  return true
}

const getCellBackground = (params: any) => {
  if (!isCellEditable(params)) {
    return 'disabled-cell'
  }
  return ''
}

const GridCellIconButtonContainer: React.FC<{ children: ReactNode }> = ({children}) => (
  <Box display='flex' justifyContent='center' alignItems='center' sx={{height: '100%'}}>
    {children}
  </Box>
)
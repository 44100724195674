import {
  Alert, Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {signIn} from "../../authService";

type AuthDialogProps = {
  isOpen: boolean,
  onClose: () => void,
  onDemoMode: () => void
}

export const AuthDialog: React.FC<AuthDialogProps> = ({isOpen, onClose, onDemoMode}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!email || !password) {
      setError('Email and password are required')
      return
    }

    try {
      await signIn(email, password)
      onClose()
      setEmail('')
      setPassword('')
    } catch (error) {
      setPassword('')
      setError('Failed to sign in. Check your credentials')
      console.error('Login error', error)
    }
  }

  const handleActivateDemoMode = () => {
    onDemoMode()
    onClose()
  }

  useEffect(() => {
    if (!isOpen) {
      setEmail('')
      setPassword('')
      setError('')
    }
  }, [isOpen])

  return <Dialog
    open={isOpen}
    onClose={(_, reason) => reason === 'backdropClick' || reason === 'escapeKeyDown' ? null : onClose()}
  >
    <form onSubmit={handleLogin}>
      <DialogTitle>Authentification</DialogTitle>
      <DialogContent>
        <TextField
          margin='dense'
          label='Email address'
          type='email'
          fullWidth
          variant='outlined'
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          autoComplete='email'
        />
        <TextField
          margin='dense'
          label='Password'
          type='password'
          fullWidth
          variant='outlined'
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          autoComplete='current-password'
        />
        {error && <Alert severity='error'>{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Box display='flex' justifyContent='space-between' alignItems='center' width='100%' paddingLeft={1} paddingRight={1}>
          <Button onClick={handleActivateDemoMode} color='primary'>Ouvrir en mode démo</Button>
          <Button type='submit' color='primary' variant='contained'>Connexion</Button>
        </Box>
      </DialogActions>
    </form>
  </Dialog>
}
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../authentication/AuthProvider";
import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import {AuthDialog} from "../dialogs/AuthDialog";
import {Authenticator} from "../authentication/Authenticator";

export const Header: React.FC = () => {
  const {user, isAuthLoading, signOutUser} = useContext(AuthContext)
  const [isAuthDialogOpen, setIsAuthDialogOpen] = useState(false)
  const {activateDemoMode} = useContext(AuthContext)

  useEffect(() => {
    if (isAuthLoading) {
      setIsAuthDialogOpen(false)
    } else {
      setIsAuthDialogOpen(!user)
    }
  }, [isAuthLoading, user])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      return "Did you save your modifications before leaving?" // for older browsers
    }

    if (user) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [user])

  const handleSignInClick = () => {
    setIsAuthDialogOpen(true)
  }

  const handleCloseAuthDialog = () => {
    setIsAuthDialogOpen(false)
  }

  return (
    <Box sx={{marginBottom: 4}}>
      <AppBar position='static'>
        <Toolbar>
          <Typography variant='h4' component='div' sx={{flexGrow: 1}}>
            CentSible
          </Typography>
          <Authenticator user={user} onSignOut={signOutUser} onSignIn={handleSignInClick}/>
        </Toolbar>
      </AppBar>
      <AuthDialog isOpen={isAuthDialogOpen} onClose={handleCloseAuthDialog} onDemoMode={activateDemoMode}/>
    </Box>
  )
}
import {Box, Button} from "@mui/material";
import React from "react";

export type PersistenceButtonsProps = {
  isSticky?: boolean,
  onSave: () => void,
  onDiscard: () => void
}

export const PersistenceButtons: React.FC<PersistenceButtonsProps> = ({ isSticky, onSave, onDiscard }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Button
        variant='outlined'
        onClick={onDiscard}
        sx={{marginRight: 1}}
      >
        Annuler
      </Button>
      <Button
        variant='contained'
        onClick={onSave}
      >
        Enregistrer
      </Button>
    </Box>
  )
}
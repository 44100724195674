import {Box, Container, useMediaQuery, useTheme} from "@mui/material";
import {Header} from "./Header";
import {Recurrence} from "../../domain/Recurrence";
import {Month} from "../../domain/Month";
import React from "react";
import WidgetPanel from "./widgets/WidgetPanel";
import {PersistenceButtons} from "./PersistenceButtons";

export type TopSectionProps = {
  recurrences: Recurrence[],
  currentBalance: string,
  handleRecurrencesUpdate: (recurrences: Recurrence[]) => void,
  firstNegativeBalanceMonth: Month | null,
  onSave: () => void,
  onDiscard: () => void,
}

export const TopSection: React.FC<TopSectionProps> = (
  {
    recurrences,
    currentBalance,
    handleRecurrencesUpdate,
    firstNegativeBalanceMonth,
    onSave,
    onDiscard
  }) => {
  const theme = useTheme()
  const isPortraitMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isLandscapeMobile = useMediaQuery(theme.breakpoints.down('landscapeMobile'))

  const topSectionOffset = isPortraitMobile ? '0px' : isLandscapeMobile ? '-210px' : '0px'

  return (
    <Box
      sx={{
        position: isPortraitMobile ? 'relative' : 'sticky',
        top: topSectionOffset,
        zIndex: 1100,
        background: 'linear-gradient(to bottom, white, white 96%, rgba(255, 255, 255, 25%))',
        paddingBottom: 2
      }}
    >
      <Header/>
      <Container>
        <WidgetPanel
          recurrences={recurrences}
          currentBalance={currentBalance}
          handleRecurrencesUpdate={handleRecurrencesUpdate}
          firstNegativeBalanceMonth={firstNegativeBalanceMonth}
        />
        <PersistenceButtons onSave={onSave} onDiscard={onDiscard}/>
      </Container>
    </Box>
  )
}
import { initializeApp } from "firebase/app"
import { getFirestore } from 'firebase/firestore'

export const firebaseConfig = {
    apiKey: 'AIzaSyBQ-xAHOt1FvB2EiHbkCBwtvJO8q0z919k',
    authDomain: 'centsible-84950.firebaseapp.com',
    projectId: 'centsible-84950',
    storageBucket: 'centsible-84950.appspot.com',
    messagingSenderId: '120232218052',
    appId: '1:120232218052:web:a89c0bf1042cb3c9d1a891'
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)

// Initialize Firestore
const db = getFirestore(firebaseApp)

export { db }
import {PaletteColor, Theme} from "@mui/material";
import {getCurrentMonth} from "./getCurrentMonth";

export const getMonthColor = (
  monthNumber: number,
  yearNumber: number,
  balance: number,
  checkedTransactionNumber: number,
  totalTransactionNumber: number,
  theme: Theme
): PaletteColor => {
  if (isMonthInThePast(monthNumber, yearNumber)) {
    const hasUncheckedTransactions = totalTransactionNumber - checkedTransactionNumber > 0
    return hasUncheckedTransactions ? theme.palette.uncheckedPastMonth : theme.palette.checkedPastMonth
  }
  return balance >= 0 ? theme.palette.positiveBalanceMonth : theme.palette.negativeBalanceMonth;
}

const isMonthInThePast = (monthNumber: number, yearNumber: number) => {
  const [currentMonth, currentYear] = getCurrentMonth()
  return yearNumber < currentYear || (yearNumber === currentYear && monthNumber < currentMonth)
}
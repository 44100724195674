import React, {useEffect, useState} from "react";
import {Collapse, Paper, useTheme} from "@mui/material";
import MonthTransactionGrid from "./MonthTransactionGrid";
import {Transaction} from "../../domain/Transaction";
import {CollapsableMonthHeader} from "./CollapsableMonthHeader";
import {getMonthColor} from "../../utils/getMonthColor";

type CollapsableMonthProps = {
  index: number,
  monthNumber: number,
  yearNumber: number,
  balance: number,
  transactions: Transaction[],
  onTransactionCreate: (month: number, year: number) => void
  onTransactionUpdate: (updatedTransaction: Transaction) => void
  onTransactionDelete: (transactionId: string) => void
  isExpanded: boolean,
  onExpandClick: (index: number) => void
}

const CollapsableMonth: React.FC<CollapsableMonthProps> = ({
   index,
   monthNumber,
   yearNumber,
   balance,
   transactions,
   onTransactionCreate,
   onTransactionUpdate,
   isExpanded,
   onExpandClick,
   onTransactionDelete
 }) => {
  const theme = useTheme()
  const [checkedTransactionNumber, setCheckedTransactionNumber] = useState<number>(0)

  useEffect(() => {
    const checkedTransactions = transactions.filter(t => t.checked)
    setCheckedTransactionNumber(checkedTransactions.length)
  }, [transactions])

  const monthColor = getMonthColor(monthNumber, yearNumber, balance, checkedTransactionNumber, transactions.length, theme)

  return (
    <Paper key={index} sx={{
      margin: '16px 0',
      borderRadius: 1,
    }}>
      <CollapsableMonthHeader
        expanded={isExpanded}
        onExpandClick={() => onExpandClick(index)}
        monthNumber={monthNumber}
        yearNumber={yearNumber}
        balance={balance}
        checkedTransactionNumber={checkedTransactionNumber}
        totalTransactionNumber={transactions.length}
        monthColor={monthColor}
        onTransactionCreate={() => onTransactionCreate(monthNumber, yearNumber)}
      />
      <Collapse in={isExpanded} timeout='auto' unmountOnExit>
        <MonthTransactionGrid
          transactions={transactions}
          onTransactionUpdate={onTransactionUpdate}
          onTransactionDelete={onTransactionDelete}
          monthColor={monthColor}
        />
      </Collapse>
    </Paper>
  )
}

export default CollapsableMonth
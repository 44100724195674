import {Month} from "../domain/Month";
import {getCurrentMonth} from "./getCurrentMonth";

export const isMonthInThePast = (month: Month): boolean => {
  const [currentMonth, currentYear] = getCurrentMonth()

  return (
    month.year < currentYear ||
    (month.year === currentYear && month.month < currentMonth)
  )
}
import {Box, useTheme} from "@mui/material";
import {formatBalanceWithTwoDecimals} from "../utils/formatValuesUtils";

type AmountWithEuroProps = {
  amount: number
}

export const AmountWithEuro: React.FC<AmountWithEuroProps> = ({amount}) => {
  const theme = useTheme()

  return (
    <Box
      style={{
        color: amount > 0 ? theme.palette.positiveAmount.main : theme.palette.text.primary,
        fontWeight: amount > 0 ? 'bold' : 'inherit'
      }}
    >
      <span>{formatBalanceWithTwoDecimals(amount)}</span>
      <span style={{
        fontSize: '0.8em',
        position: 'relative',
        top: '-0.5em'
      }}> €</span>
    </Box>
  )
}
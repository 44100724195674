import {initializeApp} from "firebase/app";
import {firebaseConfig} from "./firebase-config";
import {
  signInWithEmailAndPassword,
  getAuth,
  signOut,
  onAuthStateChanged
} from "firebase/auth"

const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)

// export const createUser = (email, password) => {
//   return createUserWithEmailAndPassword(auth, email, password)
// }

export const signIn = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password)
}

export const signOutUser = () => {
  return signOut(auth)
}

export const onAuthChange = (userHandler) => {
  const auth = getAuth()
  const unsubscribe = onAuthStateChanged(auth, userHandler)
  return unsubscribe
}
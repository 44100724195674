import {db} from "../firebase-config";
import {addDoc, collection} from "firebase/firestore";
import {Transaction} from "../domain/Transaction";
import {Recurrence} from "../domain/Recurrence";
import {sanitizeDataForFirestore} from "./sanitizeDataForFirestore";

export const saveSnapshot = async (transactions: Transaction[], recurrences: Recurrence[], repayments: Array<null>) => {

  const oneTimeTransactions = transactions.filter(transaction => !transaction.recurrenceId)
  const checkedRecurringTransactions = transactions.filter(transaction => transaction.recurrenceId && transaction.checked)

  const sanitizedOneTimeTransactions = sanitizeDataForFirestore(oneTimeTransactions)
  const sanitizedCheckedRecurringTransactions = sanitizeDataForFirestore(checkedRecurringTransactions)
  const sanitizedRecurrences = sanitizeDataForFirestore(recurrences)
  const sanitizedRepayments = sanitizeDataForFirestore(repayments)

  try {
    await addDoc(collection(db, 'snapshots'), {
      timestamp: new Date().toISOString(),
      oneTimeTransactions: sanitizedOneTimeTransactions,
      checkedRecurringTransactions: sanitizedCheckedRecurringTransactions,
      recurrences: sanitizedRecurrences,
      repayments: sanitizedRepayments
    })
  } catch (error) {
    console.error('Failed to save snapshot', error)
  }
}

export const removeUncheckedRecurringTransactions = (transactions: Transaction[]) => {
  return transactions.filter(t => !t.recurrenceId || (t.recurrenceId && t.checked))
}


import {Transaction} from "../domain/Transaction";
import {BaseMonth, Month} from "../domain/Month";
import {flagBalanceAsCalculated} from "../utils/monthBalanceUtils";

export const calculateMonthBalances = (baseMonths: BaseMonth[]): Month[] => {
  let previousMonthBalance = 0
  return baseMonths.map((baseMonth) => {
    const balance = _calculateBalance(baseMonth.transactions, previousMonthBalance)
    previousMonthBalance = balance
    return flagBalanceAsCalculated({...baseMonth, balance})
  })
}

export const _calculateBalance = (transactions: Transaction[], previousMonthBalance: number): number => {
  return transactions.reduce((monthBalance, transaction) => monthBalance + transaction.amount, previousMonthBalance)
}
import {Transaction} from "../domain/Transaction";
import {Recurrence} from "../domain/Recurrence";
import {getCurrentMonth} from "../utils/getCurrentMonth";
import config from "../config";
import {FrequencyCode} from "../domain/Frequency";
import {v4 as uuidv4} from 'uuid'

export function generateRecurrenceTransactions(
  recurrence: Recurrence,
  existingTransactions: Transaction[]
) {
  switch (recurrence.frequency) {
    case FrequencyCode.MONTH:
      return generateMonthlyRecurringTransactions(recurrence, existingTransactions)
    case FrequencyCode.YEAR:
      return generateYearlyRecurringTransactions(recurrence, existingTransactions)
    default:
      throw (new Error(`Recurrence ${recurrence.id} has neither a month or year frequency`))
  }
}

const generateMonthlyRecurringTransactions = (recurrence: Recurrence, existingTransactions: Transaction[]): Transaction[] => {
  const recurringTransactions: Transaction[] = []
  const [currentMonth, currentYear] = getCurrentMonth()
  const {appEndMonth, appEndYear} = config
  const {
    id,
    type,
    amount,
    startMonth = currentMonth,
    startYear = currentYear,
    endMonth = appEndMonth,
    endYear = appEndYear,
    description
  } = recurrence

  let year = startYear
  let month = startMonth

  while (isMonthBeforeEndOfPeriod(month, year, endMonth, endYear)) {
    // Skip transactions before the current month
    if (year > currentYear || (year === currentYear && month >= currentMonth)) {
      // Skip generation if a checked transaction already exists
      // FIXME: remove the eslint-disable
      // eslint-disable-next-line no-loop-func
      const existingTransaction = existingTransactions.find(t => t.recurrenceId === id && t.year === year && t.month === month)
      if (existingTransaction && existingTransaction.checked) {
        // Move to the next month and skip the iteration
        month++
        if (month > 12) {
          month = 1
          year++
        }
        continue
      }

      recurringTransactions.push({
        id: uuidv4(),
        year,
        month,
        description,
        amount,
        type,
        recurrenceId: id,
        checked: false
      })
    }

    // Move to the next month
    month++
    if (month > 12) {
      month = 1
      year++
    }
  }
  return recurringTransactions
}

const generateYearlyRecurringTransactions = (recurrence: Recurrence, existingTransactions: Transaction[]): Transaction[] => {
  const recurringTransactions: Transaction[] = []
  const [currentMonth, currentYear] = getCurrentMonth()
  const {appEndMonth, appEndYear} = config
  const {
    id,
    type,
    amount,
    onMonth,
    startMonth = currentMonth,
    startYear = currentYear,
    endMonth = appEndMonth,
    endYear = appEndYear,
    description
  } = recurrence

  if (!onMonth) {
    throw (new Error(`Recurrence ${id} is set as yearly but has no onMonth property`))
  }

  let year = startYear
  if (isStartMonthAfterYearlyRecurrenceMonth(startMonth, onMonth)) {
    year++
  }
  let month = onMonth

  while (isMonthBeforeEndOfPeriod(month, year, endMonth, endYear)) {
    // Skip transactions before the current month
    if (isNotMonthBeforeCurrentMonth(month, year, currentMonth, currentYear)) {
      // FIXME
      // eslint-disable-next-line no-loop-func
      const existingTransaction = existingTransactions.find(t => t.recurrenceId === id && t.year === year && t.month === month && t.checked)
      if (existingTransaction && existingTransaction.checked) {
        // Move to the next year and skip the iteration
        year++
        continue
      }

      recurringTransactions.push({
        id: uuidv4(),
        year,
        month,
        description,
        amount,
        type,
        recurrenceId: id,
        checked: false
      })
    }

    // Move to the next year
    year++
  }
  return recurringTransactions
}

const isMonthBeforeEndOfPeriod = (month: number, year: number, endMonth: number, endYear: number) => {
  return year < endYear || (year === endYear && month <= endMonth);
}

const isNotMonthBeforeCurrentMonth = (month: number, year: number, currentMonth: number, currentYear: number) => {
  return year > currentYear || (year === currentYear && month >= currentMonth);
}

const isStartMonthAfterYearlyRecurrenceMonth = (startMonth: number, onMonth: number) => {
  return startMonth > onMonth
}

import {DataGrid, GridColDef, GridRowModel} from "@mui/x-data-grid";
import {makeStyles} from "@mui/styles";
import {Box, Checkbox, IconButton, MenuItem, PaletteColor, Select, SelectChangeEvent, useTheme} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {ReactNode, useEffect, useState} from "react";
import {Transaction} from "../../domain/Transaction";
import {AmountWithEuro} from "../AmountWithEuro";
import {TransactionType, TransactionTypeCode} from "../../domain/TransactionType";
import {AccountBalanceWallet, CreditCard} from "@mui/icons-material";


const useStyles = makeStyles({
  dataGridRow: {
    paddingLeft: '50px',
    backgroundColor: 'white'
  }
})

type MonthTransactionGridProps = {
  transactions: Transaction[],
  onTransactionUpdate: (updatedTransaction: Transaction) => void,
  onTransactionDelete: (transactionId: string) => void,
  monthColor: PaletteColor
}

const MonthTransactionGrid: React.FC<MonthTransactionGridProps> = ({
                                                                     transactions,
                                                                     onTransactionUpdate,
                                                                     onTransactionDelete,
                                                                     monthColor,
                                                                   }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [gridKey, setGridKey] = React.useState<number>(0)

  useEffect(() => {
    setGridKey((prevKey) => prevKey + 1)
  }, [transactions])

  const handleProcessRowUpdate = (newRow: GridRowModel) => {
    const existingTransaction = transactions.find(t => t.id === newRow.id)

    if (!existingTransaction) {
      return newRow
    }

    const updatedTransaction = mapGridRowToExistingTransaction(newRow, existingTransaction)

    onTransactionUpdate(updatedTransaction)
    return newRow
  }

  const handleCheckboxChange = (rowId: string, checked: boolean) => {
    const existingTransaction = transactions.find(t => t.id === rowId)

    if (!existingTransaction) {
      throw (new Error(`Could not find transaction with id ${rowId} while trying to change checkbox`))
    }

    const updatedTransaction = {...existingTransaction, checked}
    onTransactionUpdate(updatedTransaction)
  }

  const handleTypeChange = (rowId: string, type: TransactionType) => {
    const existingTransaction = transactions.find(t => t.id === rowId)
    if (!existingTransaction) {
      throw (new Error(`Could not find transaction with id ${rowId} while trying to change checkbox`))
    }

    const updatedTransaction = {...existingTransaction, type}
    onTransactionUpdate(updatedTransaction)
  }

  const sortedTransactions = sortTransactions(transactions)

  const rows: GridRowModel[] = mapTransactionsToGridRows(sortedTransactions)

  const columns: GridColDef[] = [
    {
      field: 'type',
      headerName: 'Type',
      width: 50,
      editable: false,
      renderCell: (params) => (
        <TypeSelector
          currentType={params.row.type}
          onChange={(newType) => handleTypeChange(params.row.id, newType)}
        />
      )
    },
    {field: 'description', headerName: 'Description', flex: 1, editable: true},
    {
      field: 'amount',
      headerName: 'Montant',
      width: 100,
      type: 'number',
      editable: true,
      renderCell: (params) => <AmountWithEuro amount={params.value}/>
    },
    {
      field: 'delete',
      headerName: 'Supprimer',
      width: 100,
      renderCell: (params) => (
        <GridCellIconButtonContainer>
          <IconButton
            onClick={() => onTransactionDelete(params.row.id)}
            color='primary'
            disabled={!!params.row.recurrenceId}
          >
            <DeleteIcon/>
          </IconButton>
        </GridCellIconButtonContainer>
      ),
      editable: false
    },
    {
      field: 'check',
      headerName: 'Effectué',
      width: 75,
      renderCell: (params) => (
        <GridCellIconButtonContainer>
          <Box display='flex' justifyContent='center' alignItems='center' sx={{height: '100%'}}>
            <Checkbox
              color='primary'
              checked={params.row.checked}
              onChange={(event) => handleCheckboxChange(params.row.id, event.target.checked)}
            />
          </Box>
        </GridCellIconButtonContainer>
      )
    }
  ]

  return (
    <DataGrid
      key={gridKey}
      className={classes.dataGridRow}
      rows={rows}
      columns={columns}
      processRowUpdate={handleProcessRowUpdate}
      localeText={{noRowsLabel: 'Pas de transaction'}}
      hideFooter
      autoHeight
      isCellEditable={(params) => !params.row.recurrenceId}
      sx={{
        '& .MuiDataGrid-withBorderColor': {
          backgroundColor: monthColor.light,
          color: theme.palette.common.black
        }
      }}
    />
  )
}

export default MonthTransactionGrid

const mapTransactionsToGridRows = (transactions: Transaction[]): GridRowModel[] => {
  return transactions.map(transaction => ({
    id: transaction.id,
    description: transaction.description,
    amount: transaction.amount,
    recurrenceId: transaction.recurrenceId,
    checked: transaction.checked
  }))
}

const mapGridRowToExistingTransaction = (row: GridRowModel, existingTransaction: Transaction): Transaction => {
  const updatedTransaction: Partial<Transaction> = {...existingTransaction}

  /**
   * By default, rows for one-time transactions contain a `recurrenceId: undefined` property
   * This portion of code ensures that no undefined property is pushed to the transaction to update
   * This protects database services that don't allow undefined values
   */
  Object.keys(row).forEach((key: keyof GridRowModel) => {
    const value = row[key]
    if (value !== undefined && key in existingTransaction) {
      (updatedTransaction as any)[key] = value
    }
  })

  return updatedTransaction as Transaction
}

const sortTransactions = (transactions: Transaction[]): Transaction[] => {
  return [...transactions].sort((a, b) => {
    if (a.recurrenceId && !b.recurrenceId) return -1
    if (!a.recurrenceId && b.recurrenceId) return 1
    if (a.recurrenceId && b.recurrenceId) {
      return a.description.localeCompare(b.description)
    }
    return 0
  })
}

const GridCellIconButtonContainer: React.FC<{ children: ReactNode }> = ({children}) => (
  <Box display='flex' justifyContent='center' alignItems='center' sx={{height: '100%'}}>
    {children}
  </Box>
)

type TypeSelectorProps = {
  currentType: TransactionType,
  onChange: (newType: TransactionType) => void
}

export const TypeSelector: React.FC<TypeSelectorProps> = ({currentType, onChange}) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false)

  const handleTypeIconSelect = () => {
    console.log("Clicked")
    setIsSelectOpen(true)
  }

  const handleTypeChange = (event: SelectChangeEvent<TransactionType>) => {
    onChange(event.target.value as TransactionType)
    setIsSelectOpen(false)
  }

  const handleClose = () => {
    setIsSelectOpen(false)
  }

  const icon = currentType === TransactionTypeCode.CREDIT ? <CreditCard/> : <AccountBalanceWallet/>

  return (
    <Box display='flex' justifyContent='center' alignItems='center' sx={{height: '100%', position: 'relative'}}>
      <IconButton onClick={handleTypeIconSelect}
                  sx={{backgroundColor: 'primary.main', color: 'white', borderRadius: '100%'}}>
        {icon}
      </IconButton>
      <Select
        open={isSelectOpen}
        onClose={handleClose}
        value={currentType}
        onChange={handleTypeChange}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 0
        }}
      >
        <MenuItem value={TransactionTypeCode.CREDIT}><CreditCard/> Crédit</MenuItem>
        <MenuItem value={TransactionTypeCode.DEBIT}><AccountBalanceWallet/> Débit</MenuItem>
      </Select>
    </Box>
  )
}
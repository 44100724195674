import {Recurrence} from "../domain/Recurrence";
import {Transaction} from "../domain/Transaction";
import {generateRecurrenceTransactions} from "./generateRecurrenceTransactions";

export const generateRecurrencesTransactions = (recurrences: Recurrence[], existingTransactions: Transaction[]): Transaction[] => {
  let recurringTransactions: Transaction[] = []

  recurrences.forEach(recurrence => {
    recurringTransactions = [...recurringTransactions, ...generateRecurrenceTransactions(recurrence, existingTransactions)]
  })

  return recurringTransactions
}
import {Transaction} from "../domain/Transaction";
import {TransactionTypeCode} from "../domain/TransactionType";
import {v4 as uuidv4} from 'uuid';

export const handleTransactionCreate = (month: number, year: number, transactions: Transaction[]) => {
  const newTransactionDescription = '<Nouvelle transaction>'

  const newTransaction: Transaction = {
    id: uuidv4(),
    year: year,
    month: month,
    description: newTransactionDescription,
    amount: 0,
    type: TransactionTypeCode.ADJUSTMENT,
    checked: false
  }
  return [...transactions, newTransaction]
}
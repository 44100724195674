import React from 'react';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import LoanTracker from "./components/LoanTracker";
import {ThemeProvider} from "@mui/material/styles";
import {theme} from "./theme";
import {AuthProvider} from "./components/authentication/AuthProvider";

function App() {

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <LoanTracker/>
        </AuthProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;

import {Transaction} from "../domain/Transaction";

export const handleTransactionUpdate = (
  updatedTransaction: Transaction,
  transactions: Transaction[]
): Transaction[] => {
  return transactions.map(transaction =>
    transaction.id === updatedTransaction.id
    ? updatedTransaction
    : transaction
  )
}
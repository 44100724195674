import {createTheme} from "@mui/material";
import {blue, brown, green, grey, lightGreen, pink, red} from "@mui/material/colors";

declare module '@mui/material/styles' {
  interface Palette {
    disabled: Palette['primary'],
    currentBalance: Palette['primary'],
    recurrence: Palette['primary'],
    positiveAmount: Palette['primary'],
    positiveProjectedBalance: Palette['primary'],
    negativeProjectedBalance: Palette['primary'],
    positiveBalanceMonth: Palette['primary'],
    negativeBalanceMonth: Palette['primary'],
    checkedPastMonth: Palette['primary'],
    uncheckedPastMonth: Palette['primary'],
  }

  interface PaletteOptions {
    disabled: PaletteOptions['primary'],
    currentBalance: PaletteOptions['primary'],
    recurrence: PaletteOptions['primary'],
    positiveAmount: PaletteOptions['primary'],
    positiveProjectedBalance: PaletteOptions['primary'],
    negativeProjectedBalance: PaletteOptions['primary'],
    positiveBalanceMonth: Palette['primary'],
    negativeBalanceMonth: Palette['primary'],
    checkedPastMonth: PaletteOptions['primary'],
    uncheckedPastMonth: PaletteOptions['primary'],
  }
}

// Update de Button's color options to include the optional colors
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    disabled: true,
    recurrence: true
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    recurrence: true
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    landscapeMobile: true;
  }
}

const SHADES = {
  MONTH: {
    MAIN: 300,
    LIGHT: 50,
    DARK: 300,
    CONTRAST_TEXT: '#fff'
  },
  CHECKED_PAST_MONTH: {
    MAIN: 400,
    LIGHT: 50,
    DARK: 400,
    CONTRAST_TEXT: '#fff'
  },
  WIDGET: {
    MAIN: 600,
    LIGHT: 100,
    DARK: 600,
    CONTRAST_TEXT: '#fff'
  }
} as const

const CONTRAST_TEXT = {
  WIDGET: '#fff',
  MONTH: '#fff'
} as const

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      landscapeMobile: 960,
      lg: 1200,
      xl: 1536,
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
      'Leckerli One',
    ].join(','),
    h4: {
      fontFamily: 'Leckerli One',
      fontWeight: 400,
    }
  },
  palette: {
    disabled: {
      main: grey[SHADES.WIDGET.MAIN],
      contrastText: CONTRAST_TEXT.WIDGET,
      light: grey[SHADES.WIDGET.LIGHT],
      dark: grey[600]
    },
    currentBalance: {
      main: blue[SHADES.WIDGET.MAIN],
      contrastText: CONTRAST_TEXT.WIDGET,
      light: blue[SHADES.WIDGET.LIGHT],
      dark: blue[SHADES.WIDGET.DARK]
    },
    recurrence: {
      main: pink[SHADES.WIDGET.MAIN],
      contrastText: CONTRAST_TEXT.WIDGET,
      light: pink[SHADES.WIDGET.LIGHT],
      dark: pink[SHADES.WIDGET.DARK]
    },
    positiveAmount: {
      main: lightGreen['A700'],
      contrastText: CONTRAST_TEXT.WIDGET,
      light: lightGreen['A700'],
      dark: lightGreen['A700']
    },
    positiveProjectedBalance: {
      main: green[SHADES.WIDGET.MAIN],
      contrastText: CONTRAST_TEXT.WIDGET,
      light: green[SHADES.WIDGET.LIGHT],
      dark: green[SHADES.WIDGET.DARK]
    },
    negativeProjectedBalance: {
      main: red[SHADES.WIDGET.MAIN],
      contrastText: CONTRAST_TEXT.WIDGET,
      light: red[SHADES.WIDGET.LIGHT],
      dark: red[SHADES.WIDGET.DARK]
    },
    positiveBalanceMonth: {
      main: blue[SHADES.MONTH.MAIN],
      contrastText: CONTRAST_TEXT.MONTH,
      light: blue[SHADES.MONTH.LIGHT],
      dark: blue[SHADES.MONTH.DARK]
    },
    negativeBalanceMonth: {
      main: red[SHADES.MONTH.MAIN],
      contrastText: CONTRAST_TEXT.MONTH,
      light: red[SHADES.MONTH.LIGHT],
      dark: red[SHADES.MONTH.DARK]
    },
    checkedPastMonth: {
      main: grey[SHADES.CHECKED_PAST_MONTH.MAIN],
      contrastText: CONTRAST_TEXT.MONTH,
      light: grey[SHADES.CHECKED_PAST_MONTH.LIGHT],
      dark: grey[SHADES.CHECKED_PAST_MONTH.DARK]
    },
    uncheckedPastMonth: {
      main: brown[SHADES.CHECKED_PAST_MONTH.MAIN],
      contrastText: CONTRAST_TEXT.MONTH,
      light: brown[SHADES.CHECKED_PAST_MONTH.LIGHT],
      dark: brown[SHADES.CHECKED_PAST_MONTH.DARK]
    }
  }
})
import {Transaction} from "../domain/Transaction";
import {Recurrence} from "../domain/Recurrence";
import {db} from "../firebase-config";
import {collection, getDocs, limit, orderBy, query} from "firebase/firestore";

export const loadLatestSnapshot = async (): Promise<[Transaction[], Recurrence[], []]> => {
  const snapshotsRef = collection(db, 'snapshots')
  const q = query(snapshotsRef, orderBy('timestamp', 'desc'), limit(1))

  const querySnapshot = await getDocs(q)
  if(querySnapshot.empty) {
    throw new Error('No snapshots found')
  }

  const latestSnapshot = querySnapshot.docs[0].data()

  const onTimeTransactions: Transaction[] = latestSnapshot.oneTimeTransactions || []
  const checkedRecurringTransactions: Transaction[] = latestSnapshot.checkedRecurringTransactions || []
  const transactions = [...onTimeTransactions, ...checkedRecurringTransactions]

  const recurrences: Recurrence[] = latestSnapshot.recurrences || []

  return [transactions, recurrences, []]
}
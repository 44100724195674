export type Frequency = 'month' | 'year'

export const FrequencyCode: Record<string, Frequency> = {
  MONTH: 'month',
  YEAR: 'year'
} as const

export const FrequencyNameMapping = {
  [FrequencyCode.MONTH]: 'Mensuelle',
  [FrequencyCode.YEAR]: 'Annuelle'
}
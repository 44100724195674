import {
  Box, IconButton, Typography,
  useTheme
} from "@mui/material";
import React, {useState} from "react";
import {WidgetCard} from "./WidgetCard";
import {RecurrenceDialog} from "../../dialogs/RecurrenceDialog";
import {Recurrence} from "../../../domain/Recurrence";
import {formatMonthAndYear} from "../../../utils/formatValuesUtils";
import {Month} from "../../../domain/Month";
import OpenDialogIcon from '@mui/icons-material/LaunchRounded';

type WidgetPanelProps = {
  recurrences: Recurrence[],
  currentBalance: string,
  handleRecurrencesUpdate: (recurrences: Recurrence[]) => void,
  firstNegativeBalanceMonth: Month | null
}

const WidgetPanel: React.FC<WidgetPanelProps> = ({
                                                   recurrences,
                                                   currentBalance,
                                                   handleRecurrencesUpdate,
                                                   firstNegativeBalanceMonth
                                                 }) => {
  const theme = useTheme()
  const [isRecurrenceDialogOpen, setIsRecurrenceDialogOpen] = useState(false)

  const handleOpenRecurrenceDialog = () => {
    setIsRecurrenceDialogOpen(true)
  }

  const handleCloseRecurrenceDialog = () => {
    setIsRecurrenceDialogOpen(false)
  }

  const isProjectedBalanceNegative = () => {
    return !!firstNegativeBalanceMonth
  }

  return (
    <Box sx={{top: '64px', zIndex: 1000, backgroundColor: 'white', paddingTop: 1}}>
      <Box sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
        gap: 1,
        minHeight: '100px',
        paddingBottom: 2,
      }}>
        <WidgetCard
          title='Récurrences'
          themeColor={theme.palette.recurrence}
          onClick={handleOpenRecurrenceDialog}
        >
          <IconButton color='recurrence'>
            <OpenDialogIcon/>
          </IconButton>
        </WidgetCard>

        <WidgetCard
          title='Solde'
          themeColor={theme.palette.currentBalance}
          onClick={() => {}}
        >
          <Typography variant='h5' sx={{fontWeight: 'bold'}}>
            {`${currentBalance} €`}
          </Typography>
        </WidgetCard>

        <WidgetCard
          title={`Prévisionnel: ${isProjectedBalanceNegative() ? 'Découvert' : 'OK'}`}
          themeColor={isProjectedBalanceNegative() ? theme.palette.negativeProjectedBalance : theme.palette.positiveProjectedBalance}
          onClick={() => () => {
          }}
        >
          {isProjectedBalanceNegative()
            ? <Typography variant='h6'>
              {formatMonthAndYear(firstNegativeBalanceMonth!.month, firstNegativeBalanceMonth!.year)}
            </Typography>
            : null
          }
        </WidgetCard>

        <WidgetCard
          title='Épargne'
          themeColor={theme.palette.disabled}
          onClick={() => () => {
          }}
          disabled={true}
        />

        <WidgetCard
          title='Remboursement'
          themeColor={theme.palette.disabled}
          onClick={() => () => {
          }}
          disabled={true}
        />

        <WidgetCard
          title='Historique'
          themeColor={theme.palette.disabled}
          onClick={() => () => {
          }}
          disabled={true}
        />
      </Box>

      <RecurrenceDialog
        isOpen={isRecurrenceDialogOpen}
        onClose={handleCloseRecurrenceDialog}
        recurrences={recurrences}
        handleRecurrencesUpdate={handleRecurrencesUpdate}
      />
    </Box>
  )
}

export default WidgetPanel
import {
  Alert,
  Box,
  CircularProgress,
  Container, Snackbar,
  Typography
} from "@mui/material";
import MonthTable from "./months/MonthTable";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Recurrence} from "../domain/Recurrence";
import {generateRecurrencesTransactions} from "../service/generateRecurrencesTransactions";
import {Transaction} from "../domain/Transaction";
import {organizeTransactionsByMonth} from "../utils/organizeTransactionsByMonth";
import {handleTransactionUpdate} from "../service/handleTransactionUpdate";
import {handleTransactionDelete} from "../service/handleTransactionDelete";
import {calculateMonthBalances} from "../service/calculateMonthBalances";
import {removeUncheckedRecurringTransactions, saveSnapshot} from "../utils/saveSnapshotUtils";
import {AuthContext} from "./authentication/AuthProvider";
import {loadLatestSnapshot} from "../utils/loadLatestSnapshot";
import {handleTransactionCreate} from "../service/handleTransactionCreate";
import {getFirstNegativeBalanceMonthToCome} from "../service/getFirstNegativeBalanceMonthToCome";
import {Month} from "../domain/Month";
import {AlertSeverity, AlertSeverityCode} from "../domain/AlertSeverity";
import {getCurrentMonth} from "../utils/getCurrentMonth";
import {TopSection} from "./topSection/TopSection";

const LoanTracker: React.FC = () => {
  const {user, isAuthLoading, isDemoMode} = useContext(AuthContext)

  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [recurrences, setRecurrences] = useState<Recurrence[]>([])
  const [months, setMonths] = useState<Month[]>([])
  const [currentBalance, setCurrentBalance] = useState<string>('')
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const previousRecurrencesState = useRef<Recurrence[]>()

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertSeverity>(AlertSeverityCode.SUCCESS)

  const loadData = useCallback(async () => {
    setIsDataLoading(true)
    try {
      if (isDemoMode) {
        const demoTransactions: Transaction[] = []
        const demoRecurrences: Recurrence[] = []
        setTransactions(demoTransactions)
        setRecurrences(demoRecurrences)
      } else if (user) {
        const [loadedTransactions, loadedRecurrences] = await loadLatestSnapshot()
        const recurringTransactions = generateRecurrencesTransactions(loadedRecurrences, loadedTransactions)
        setTransactions([...loadedTransactions, ...recurringTransactions])
        setRecurrences(loadedRecurrences)
      } else {
        setTransactions([])
        setRecurrences([])
        setMonths([])
      }
    } catch (err: any) {
      console.error(err)
      setError(`${err.message} --- ${err.stack}`)
    } finally {
      setIsDataLoading(false)
    }
  }, [user, isDemoMode])

  useEffect(() => {
      loadData()
  }, [user, isDemoMode, loadData])

  useEffect(() => {
    const baseMonthsWithTransactions = organizeTransactionsByMonth(transactions)
    const balancedMonths = calculateMonthBalances(baseMonthsWithTransactions)
    setMonths(balancedMonths)
  }, [transactions, user])

  useEffect(() => {
    const [currentMonth, currentYear] = getCurrentMonth()
    const balance = transactions
      .filter(t => t.checked && (t.year < currentYear || (t.year === currentYear && t.month <= currentMonth)))
      .reduce((sum, transaction) => sum + transaction.amount, 0)
    setCurrentBalance(formatBalanceWithTwoDecimals(balance))
  }, [transactions])

  function hasRecurrenceStateChanged(recurrences: Recurrence[]) {
    if (JSON.stringify(previousRecurrencesState.current) !== JSON.stringify(recurrences)) {
      previousRecurrencesState.current = recurrences
      return true
    }
    return false
  }

  useEffect(() => {
    if (hasRecurrenceStateChanged(recurrences)) {
      const recurringTransactions = generateRecurrencesTransactions(recurrences, transactions)
      const oneTimeTransactions = removeUncheckedRecurringTransactions(transactions)
      setTransactions([...recurringTransactions, ...oneTimeTransactions])
    }
  }, [recurrences, transactions])

  const handleTransactionCreateWrapper = (month: number, year: number) => {
    setTransactions((prevTransactions) => handleTransactionCreate(month, year, prevTransactions))
  }

  const handleTransactionUpdateWrapper = (updatedTransaction: Transaction) => {
    setTransactions((prevTransactions) => handleTransactionUpdate(updatedTransaction, prevTransactions))
  }

  const handleTransactionDeleteWrapper = (deletedTransactionId: string) => {
    setTransactions(handleTransactionDelete(deletedTransactionId, transactions))
  }

  const handleRecurrencesUpdateWrapper = (updatedRecurrences: Recurrence[]) => {
    setRecurrences(updatedRecurrences)
  }

  const handleSaveSnapshot = () => {
    if (isDemoMode) {
      console.error('Cannot save snapshot in demo mode')
      setSnackbarSeverity(AlertSeverityCode.INFO)
      setSnackbarMessage('Les données ne peuvent pas être sauvegardées en mode Démo')
      setSnackbarOpen(true)
      return
    }
    if (!user) {
      console.error('No authenticated user. Cannot save snapshot.')
      setSnackbarSeverity(AlertSeverityCode.ERROR)
      setSnackbarMessage("Pas d'utilisateur authentifié. Impossible de sauvegarder les modifications.")
      setSnackbarOpen(true)
      return
    }
    saveSnapshot(transactions, recurrences, [])
      .then(() => {
        setSnackbarSeverity(AlertSeverityCode.SUCCESS)
        setSnackbarMessage("Modifications enregistrées")
        setSnackbarOpen(true)
      })
      .catch((error) => {
        console.error("Save data failure", error)
        setSnackbarSeverity(AlertSeverityCode.ERROR)
        setSnackbarMessage("Erreur inconnue durant la sauvegarde des modifications.")
        setSnackbarOpen(true)
      })
  }

  if (isAuthLoading || isDataLoading) {
    return <CircularProgress/>
  }

  if (error) {
    return <Typography> Error: {error}</Typography>
  }

  return <Box>
    <TopSection
      recurrences={recurrences}
      currentBalance={currentBalance}
      handleRecurrencesUpdate={handleRecurrencesUpdateWrapper}
      firstNegativeBalanceMonth={getFirstNegativeBalanceMonthToCome(months)}
      onSave={handleSaveSnapshot}
      onDiscard={loadData}
    />
    <Container>
      <MonthTable
        months={months}
        handleTransactionCreate={handleTransactionCreateWrapper}
        handleTransactionUpdate={handleTransactionUpdateWrapper}
        handleTransactionDelete={handleTransactionDeleteWrapper}
      />
    </Container>
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={5000}
      onClose={() => setSnackbarOpen(false)}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
    >
      <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{width: '100%'}}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  </Box>;
}

export default LoanTracker

const formatBalanceWithTwoDecimals = (balance: number) => {
  return (Math.round(balance * 100) / 100).toFixed(2).replace('.', ',')
}
import {CardActionArea, PaletteColor, Typography} from "@mui/material";
import React, {ReactNode} from "react";

type WidgetCardProps = {
  title: string,
  themeColor: PaletteColor
  onClick: () => void,
  disabled?: boolean,
  children?: ReactNode
}

export const WidgetCard: React.FC<WidgetCardProps> = ({title, themeColor, onClick, disabled = false, children}) => {
  return <CardActionArea
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100%",
      borderRadius: 2,
      color: themeColor.main,
      backgroundColor: themeColor.light
    }}
    onClick={onClick}
    disabled={disabled}
  >
    <Typography
      textAlign="center"
      variant="h6"
      sx={{
        fontWeight: "bold",
        fontStyle: "italic"
      }}
    >
      {disabled ? '' : title}
    </Typography>
    {children}
  </CardActionArea>;
}
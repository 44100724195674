import {BaseMonth, Month} from "../domain/Month";

export const createBaseMonth = (month: number, year: number): BaseMonth => ({
  id: `${month}-${year}`,
  month: month,
  year: year,
  transactions: [],
  balance: 0
})

export const flagBalanceAsCalculated = (month: BaseMonth): Month => ({
  ...month,
  __brand: "CalculatedBalance"
})